import React, { useReducer, createContext, useMemo } from "react";
import {
  getDefaultDeploymentName,
  getDefaultReleaseName,
  getDefaultConfigName,
} from "../vehicle/Utils";
import { DEPLOYMENT_TYPES } from "../../services/Constants";

export const WizardContext = createContext();

export const ACTIONS = {
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  UPDATE_VEHICLE_GROUP: "UPDATE_VEHICLE_GROUP",
  TOGGLE_VEHICLE: "TOGGLE_VEHICLE",
  TOGGLE_RELEASE: "TOGGLE_RELEASE",
  UPDATE_RELEASE_NAME: "UPDATE_RELEASE_NAME",
  UPDATE_RELEASE: "UPDATE_RELEASE",
  UPDATE_IMAGES: "UPDATE_IMAGES",
  UPDATE_DEPLOYMENT_NAME: "UPDATE_DEPLOYMENT_NAME",
  UPDATE_CONFIG_NAME: "UPDATE_CONFIG_NAME",
  UPDATE_CONFIG_DESCRIPTION: "UPDATE_CONFIG_DESCRIPTION",
  UPDATE_CAMPAIGN_END_DATE: "UPDATE_CAMPAIGN_END_DATE",
  UPDATE_CAMPAIGN_START_DATE: "UPDATE_CAMPAIGN_START_DATE",
  TOGGLE_WIZARD: "TOGGLE_WIZARD",
  UPDATE_STEP: 0,
  MAKE_RELEASE: "MAKE_RELEASE",
  MAKE_DEPLOYMENT: "MAKE_DEPLOYMENT",
  RESET: "RESET",
  TOGGLE_BUTTON: "TOGGLE_BUTTON",
  SHOW_CONFIG_WIZARD: "SHOW_CONFIG_WIZARD",
  SHOW_SOFTWARE_WIZARD: "SHOW_SOFTWARE_WIZARD",
  UPDATE_CUSTOMER_MODULES: "UPDATE_CUSTOMER_MODULES",
  UPDATE_DEPLOYMENT_TYPE: "UPDATE_DEPLOYMENT_TYPE",
  DEPLOYMENT_MESSAGE: "DEPLOYMENT_MESSAGE",
};

export const TABS = {
  CHOOSE_VEHICLES: 0,
  CHOOSE_VEHICLE_GROUPS: 1,
  CREATE_RELEASE: 1,
  CHOOSE_RELEASE: 0,
};

const reducer = (state, action) => {
  const { type = "", payload = {} } = action;
  switch (type) {
    case ACTIONS.UPDATE_STEP:
    case ACTIONS.UPDATE_VEHICLE:
    case ACTIONS.UPDATE_VEHICLE_GROUP:
    case ACTIONS.TOGGLE_VEHICLE:
    case ACTIONS.TOGGLE_RELEASE:
    case ACTIONS.UPDATE_RELEASE_NAME:
    case ACTIONS.UPDATE_RELEASE:
    case ACTIONS.UPDATE_IMAGES:
    case ACTIONS.UPDATE_DEPLOYMENT_NAME:
    case ACTIONS.UPDATE_CAMPAIGN_START_DATE:
    case ACTIONS.UPDATE_CAMPAIGN_END_DATE:
    case ACTIONS.TOGGLE_BUTTON:
    case ACTIONS.TOGGLE_WIZARD:
    case ACTIONS.UPDATE_CONFIG_NAME:
    case ACTIONS.UPDATE_CONFIG_DESCRIPTION:
    case ACTIONS.UPDATE_CUSTOMER_MODULES:
    case ACTIONS.DEPLOYMENT_MESSAGE:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.MAKE_RELEASE:
      return {
        ...state,
        ...payload,
        showWizard: true,
        activeStep: 0,
        disableNextBtn: false,
      };
    case ACTIONS.MAKE_DEPLOYMENT:
      return {
        ...state,
        ...payload,
        showWizard: true,
        activeStep: 0,
      };
    case ACTIONS.SHOW_CONFIG_WIZARD:
      return {
        ...state,
        wizardType: "configuration",
        campaignStartDate: new Date(),
        showWizard: true,
      };
    case ACTIONS.SHOW_SOFTWARE_WIZARD:
      return {
        ...state,
        wizardType: "software",
        campaignStartDate: new Date(),
        showWizard: true,
      };
    case ACTIONS.RESET:
      return {
        ...initialState,
        modules: state.modules,
        customerData: state.customerData,
        deploymentName: getDefaultDeploymentName(),
        configName: getDefaultConfigName(),
        releaseName: getDefaultReleaseName(),
      };
    default:
      return state;
  }
};

const getMinCampaignEndDate = () => {
  const sd = new Date();
  return new Date(sd.setDate(sd.getDate() + 7));
};

const initialState = {
  vehicles: [],
  vehicleGroups: [],
  toggleVehicle: TABS.CHOOSE_VEHICLES,
  toggleRelease: TABS.CHOOSE_RELEASE,
  releaseName: getDefaultReleaseName(),
  release: {},
  images: [],
  campaignEndDate: null, //getMinCampaignEndDate(),
  campaignStartDate: new Date(),
  deploymentName: getDefaultDeploymentName(),
  deploymentId: "",
  deploymentType: DEPLOYMENT_TYPES.SNAPSHOT,
  configId: "",
  configName: getDefaultConfigName(),
  configDescription: "",
  configState: {},
  wizardType: "",
  showWizard: false,
  activeStep: 0,
  disableNextBtn: true,
  launch: false,
  modules: [],
  customerData: [],
  deploymentMessage: '',
};

export const WizardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // To avoid re rendering we are using useMemo()
  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <WizardContext.Provider value={contextValue}>
      {children}
    </WizardContext.Provider>
  );
};
