import React, { Fragment, useContext } from "react";
import { WizardContext, ACTIONS } from "../wizard/Context";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import {
  Grid,
  CardActionArea,
} from "@mui/material";
import { useQuery } from "react-query";
import { getStatsForDashboard } from "../../services/Dashboard";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";
import DottedTile from "../common/DottedTile";
import InfoTile from "../common/InfoTile";

const DeploymentStats = () => {
  const { validateModuleWithMessage } = useModule();
  const { dispatch } = useContext(WizardContext);
  const { data: { deployments = 0 } = {} } = useQuery(
    "getStatsForDashboard",
    getStatsForDashboard,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Deployments"} info={deployments} />
      </Grid>

      <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
        <Grid item xs={12} sm={6} md={2}>
          <CardActionArea
            onClick={() => {
              if (validateModuleWithMessage(MODULES.OTA)) {
                dispatch({
                  type: ACTIONS.SHOW_SOFTWARE_WIZARD,
                })
              }
            }
            }
          >
            <DottedTile title={"CREATE A DEPLOYMENT"}>
              <RocketLaunchIcon
                sx={{ fontSize: "3.5rem", color: grey[500] }}
              />
            </DottedTile>
          </CardActionArea>
        </Grid>
      </Check>
    </Fragment>
  );
};

DeploymentStats.defaultProps = {};

DeploymentStats.propTypes = {};

export default DeploymentStats;
