import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { Box, Chip, Alert } from "@mui/material";
import {
  getVehicleSubscription,
  getVehicleSubscriptionAudit,
} from "../../services/vehicle";
import { DataGrid } from "@mui/x-data-grid";
import Constants, { MODULES, MODULE_TEXT } from "../../services/Constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import StatusActiveInactive from "../common/StatusActiveInactive";
import useModule from "../../hooks/useModule";
import { detailsTabsBoxStyle } from "../../services/Utils";

const displayServiceName = (name) => {
  if (!!name) {
    const parts = name
      .split("_")
      .map((i) => i.charAt(0).toUpperCase() + i.slice(1));
    return parts.join(" ");
  }
};

export default function VehicleSubscriptionList({ vehicleData, isLoading }) {
  const { validate } = useModule();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!validate(MODULES.SUBSCRIPTION)) {
    return <Box {...detailsTabsBoxStyle}>
      <Alert variant="outlined" severity="warning">
        {MODULE_TEXT.SUBSCRIPTION} module not enabled for {localStorage.selectedCustomer} customer
      </Alert>
    </Box>
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Subscriptions" />
          <Tab label="Subscription Audit" />
        </Tabs>
      </Box>
      {value === 1 ? (
        <SubscriptionsAuditList vehicleData={vehicleData} />
      ) : (
        <SubscriptionList vehicleData={vehicleData} />
      )}
    </>
  );
}

const SubscriptionList = ({ vehicleData }) => {
  const [pageSize, setPageSize] = useState(Constants.PAGE_SIZE);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const processSubscriptionData = (subData) => {
    if (subData && Object.keys(subData).length) {
      const { features } = subData;
      if (features && features?.length) {
        return features
          .map((feature) => {
            const { services = [], name = "" } = feature;
            const list = services.map((i) => ({
              ...i,
              featureName: name,
            }));
            return list;
          })
          ?.flat();
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const { data } = useQuery(
    ["vehicleSubscriptions", vehicleData?.device?.vehicleKey],
    getVehicleSubscription,
    {
      onSuccess: (res) => {
        console.log("subscription response is ", res);
        const result = processSubscriptionData(res);
        setSubscriptionData(result);
      },
      refetchOnWindowFocus: false,
      enabled: !!vehicleData?.device?.vehicleKey,
    }
  );

  const renderServiceName = (i) => {
    return displayServiceName(i);
  };

  const getColumnsData = (item) => {
    return [
      {
        field: "featureName",
        headerName: "Feature name",
        width: 150,
        renderCell: (d) => renderServiceName(d.value),
      },
      {
        field: "name",
        headerName: "Service name",
        width: 150,
        renderCell: (d) => d.value,
      } /*,
      {
        field: "createdAt",
        headerName: "Created On",
        width: 200,
        renderCell: (d) => moment(d.value).format("L, h:mm:ss a")          
      }*/,
      {
        field: "subscriptionId",
        headerName: "Subscription ID",
        width: 350,
      },
      {
        field: "subscriptionStartDate",
        headerName: "Subscription Start date",
        width: 200,
        renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
      },
      {
        field: "subscriptionEndDate",
        headerName: "Subscription End date",
        width: 200,
        renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
      },
      {
        field: "transactionId",
        headerName: "Transaction ID",
        width: 150,
      },
      {
        field: "pos",
        headerName: "POS",
        width: 150,
      },
    ];
  };
  const columns = getColumnsData();
  return (
    <DataGrid
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
      pagination
      rows={subscriptionData || []}
      columns={columns}
      sx={{ minHeight: 700 }}
      getRowId={(row) => row?.id + row?.subscriptionId}
    />
  );
};

const SubscriptionsAuditList = ({ vehicleData }) => {
  const [pageSize, setPageSize] = useState(Constants.PAGE_SIZE);
  const getAuditColumnsData = (item) => {
    return [
      {
        field: "createdAt",
        headerName: "Created On",
        width: 200,
        renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
      },
      {
        field: "serviceName",
        headerName: "Service name",
        width: 150,
        renderCell: (d) => d.value,
      },
      {
        field: "subscriptionId",
        headerName: "Subscription ID",
        width: 350,
      },
      {
        field: "active",
        headerName: "Status",
        width: 150,
        renderCell: ({ row }) => <StatusActiveInactive active={row?.active} />,
      },
      {
        field: "subscriptionStartDate",
        headerName: "Subscription Start date",
        width: 200,
        renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
      },
      {
        field: "subscriptionEndDate",
        headerName: "Subscription End date",
        width: 200,
        renderCell: (d) => <DateAndTimeGridCell value={d.value} />,
      },
      {
        field: "transaction",
        headerName: "Transaction",
        width: 150,
      },
      {
        field: "pos",
        headerName: "POS",
        width: 150,
      },
    ];
  };

  const auditColumns = getAuditColumnsData();

  const { data: auditData } = useQuery(
    ["vehicleSubscriptionsAudit", vehicleData?.device?.vehicleKey],
    getVehicleSubscriptionAudit,
    {
      refetchOnWindowFocus: false,
      enabled: !!vehicleData?.device?.vehicleKey,
    }
  );

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: "createdAt", sort: "desc" }],
        },
      }}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPT}
      pagination
      rows={auditData?.subscriptionAudit || []}
      columns={auditColumns}
      sx={{ minHeight: 700 }}
      getRowId={(row) => row.subscriptionAuditId}
    />
  );
};
