import React, { useState } from 'react';
import styled from "styled-components";
import {
    Button,
    Popover,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useQuery } from "react-query";
import { extractCSVData, CSVMaker, DownloadCSV } from './exportChunks';

const ExportWrapper = styled.div`
  margin: 0% 0% 1% 0.5%;
  padding: 0;
`;

const ExportOption = ({ data, endpointFn, queryName }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'export-popover' : undefined;

    const [initiate, setInitiate] = useState(false);
    //Queries
    const { isLoading, isFetching } =
        useQuery(
            data,
            endpointFn,
            {
                onSuccess: (response) => {
                    const csv = extractCSVData(response, queryName);
                    const csvdata = CSVMaker(csv);
                    DownloadCSV(csvdata);
                    setInitiate(false)
                },
                enabled: initiate,
                retry: 0,
                refetchOnWindowFocus: false
            }
        );

    return <ExportWrapper>
        <Button aria-describedby={id}
            variant="text"
            disabled={(isLoading || isFetching) && true}
            startIcon={(isLoading || isFetching) ? <HourglassTopIcon /> : <GetAppIcon />}
            onClick={handleClick}
        >
            Export
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => { handleClose(); setInitiate(true) }}>
                        <ListItemText primary="Download as CSV" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>
    </ExportWrapper >
}

export default ExportOption;