import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import { grey } from "@mui/material/colors";
import styled from "styled-components";
import UserRoleManager from "./UserRoleManager";
import UserDisableBtn from "./UserDisableBtn";
import UserConfirmBtn from "./UserConfirmBtn";
import { formatDate, stringToHslColor } from "../../services/Utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Constants from "../../services/Constants";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import UserOrgManager from "./UserOrgManager";

const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const getColumnsData = (params) => {
  return [
    {
      field: "userName",
      headerName: "Name",
      width: 250,
      renderCell: (data) => {
        const { row = {} } = data;
        const {
          userName = "",
          enabled = false,
          status = "",
          userId,
          details,
        } = row || {};
        return (
          <div className="user-account-block">
            <Avatar
              sx={{
                bgcolor: enabled
                  ? stringToHslColor(userName, 40, 40)
                  : grey[500],
              }}
            >
              {userName.charAt(0).toUpperCase()}
            </Avatar>
            <p className="account-username">
              <RouterLink
                to={`/Users/${details?.userId ? details?.userId : userId}`}
              >
                {userName}
              </RouterLink>
              {status === Constants.USER_STATUS.CONFIRMED && (
                <CheckCircleIcon
                  className="user-confirmed-icon"
                  sx={{ fontSize: 14 }}
                  color={enabled ? "primary" : "inherit"}
                />
              )}
            </p>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => {
        const { row = {} } = data;
        return <UserRoleManager {...params} {...row} />;
      },
    },
    {
      field: "org",
      headerName: "Organization",
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => {
        const { row = {} } = data;
        return <UserOrgManager {...params} {...row} />;
      },
    },
    {
      field: "createdDate",
      headerName: "Added on",
      width: 150,
      editable: false,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdDate} />
      ),
    },
    {
      field: "empty",
      headerName: "Actions",
      width: 200,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (data) => {
        const { row = {} } = data;
        return (
          <BtnWrapper>
            <UserDisableBtn {...params} {...row} />
            <UserConfirmBtn {...params} {...row} />
          </BtnWrapper>
        );
      },
    },
  ];
};

export { getColumnsData };
