import { formatFileSize } from '../../services/Utils';

const CSVMaker = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);

    csvRows.push(headers.join(','));
    for (const row of data) {
        const values = headers.map(e => {
            return row[e]?.replace(",", "")
        })
        csvRows.push(values.join(','))
    }

    return csvRows.join('\n')
}

const DownloadCSV = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create an anchor tag for downloading
    const a = document.createElement('a');
    // Set the URL and download attribute of the anchor tag
    a.href = url;
    a.download = 'exportOTA.csv';
    // Trigger the download by clicking the anchor tag
    a.click();
}

const extractCSVData = (response, queryName) => {

    let data;

    if (queryName === 'deployments') {
        const { data: { res = [] } = {} } = response || {};
        data = res.map(({
            deploymentName,
            release: { releaseName },
            campaignStartDate,
            campaignEndDate,
            createdBy,
            campaignCreationDate,
            deploymentStatus,
            deploymentType,
            jobId
        }) => ({
            deploymentName,
            releaseName,
            campaignStartDate,
            campaignEndDate,
            createdBy,
            campaignCreationDate,
            deploymentStatus,
            deploymentType,
            jobId
        }));
    } else if (queryName === 'releases') {
        const { data: { res = [] } = {} } = response || {};
        data = res.map(({
            releaseName,
            createdBy,
            createdAt,
            imagesCount,
            releaseId,
        }) => ({
            releaseName,
            createdBy,
            createdAt,
            imagesCount,
            releaseId,
        }));
    } else if (queryName === 'images') {
        const { data: { res = [] } = {} } = response || {};
        data = res.map(({
            fileName,
            fileSize,
            version,
            baseVersion,
            campaignType,
            uploadedBy,
            uploadStatus,
            createdAt,
            imageUploadId,
        }) => ({
            fileName,
            fileSize: formatFileSize(parseInt(fileSize)),
            version,
            baseVersion,
            campaignType,
            uploadedBy,
            uploadStatus,
            createdAt,
            imageUploadId,
        }));
    } else if (queryName === 'vehicles') {
        const { data: { res = [] } = {} } = response || {};
        data = res.map(({
            serialNo,
            active,
            certAvailable,
            vehicleKey,
            serialNoCreatedAt,
            vehicleKeyUpdatedAt,
        }) => ({
            serialNo,
            status: active ? 'Active' : 'Inactive',
            certificate: certAvailable ? 'Available' : 'Not Available',
            vehicleKey,
            HUManufacturingDate: serialNoCreatedAt,
            vehicleManufactured: vehicleKeyUpdatedAt,
        }));
    }

    return data;
}

export {
    extractCSVData,
    CSVMaker,
    DownloadCSV,
}